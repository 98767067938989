import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import {Popup, TextFieldSelect} from '@partssourceinc/react-ui-core';

import 'less/bulkActions.less';

export default class BulkActionsForm extends React.Component {
    static propTypes = {
        requesterList: PropTypes.array.isRequired,
        items: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    };
  
    constructor(props) {
        super(props);
  
        this.state = {
            saving: false,
            selectedRequester: {},
            showErrors: false,
        };
    }
  
    onSave() {
        const {selectedRequester} = this.state;
        const {items, onSave} = this.props;
  
        if (!selectedRequester.contactId) {
            this.setState({showErrors: true});
            return;
        }
        this.setState({saving: true});
        const facilityId = items[0].facilityId;
        axios.post('/ShoppingService/api/v1/lineItems/update/requester', {FacilityId: facilityId, RequesterId: selectedRequester.contactId}).then(() => {
            onSave();
        })
    }
  
    renderItem(cartItem) {
        let workOrderNumber = '';
        if (cartItem.fields) {
            let workOrderField = cartItem.fields.find(x => x.fieldUid === '22222222-2222-2222-2222-222222222222');
            if (workOrderField) workOrderNumber = workOrderField.value;
        }
  
        return (
            <div className="bulk-item-wrapper">
                <div className="bulk-product-image">
                    {cartItem.imagePath ?
                        <img src={`${cartItem.imagePath}?wid=125`} style={{width: '125px'}} /> :
                        <img src="https://assets.partsfinder.com/image/ps-no-image_rectangle?wid=125" style={{width: '125px'}} alt="Not Available" />
                    }
                </div>
                <div className="bulk-product-details">
                    <div className="bulk-product-description">{cartItem.partNumber} - {cartItem.description}</div>
                    <div className="item-attribute">
                        <span>Facility:</span>
                        <span>{cartItem.facilityName}</span>
                    </div>
                    <div className="item-attribute">
                        <span>Reference #:</span>
                        <span>{cartItem.lineItemId}</span>
                    </div>
                    <div className="item-attribute">
                        <span>Requester:</span>
                        <span>{cartItem.requestorName}</span>
                    </div>
                    {workOrderNumber && <div className="item-attribute">
                        <span>Work Order #:</span>
                        <span>{workOrderNumber}</span>
                    </div>}
                </div>
            </div>
        )
    }
  
    render() {
        const {selectedRequester, showErrors, saving} = this.state;
        const {onClose, requesterList, items} = this.props;

        return (
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                onCancel={onClose}
                className="bulk-actions-dialog"
                hideButtons={false}
                loading={saving}
                onConfirm={::this.onSave}
            >
                <div className="bulk-actions-wrapper">
                    <h2>Bulk Actions</h2>
                    <div className="action-row">
                        <TextFieldSelect
                            keyField="contactId"
                            valueField="fullName"
                            onSelect={contact => this.setState({selectedRequester: contact})}
                            listItems={requesterList}
                            label="Select Requester"
                            placeHolder="Select Requester"
                            selectedItem={selectedRequester}
                            disableClear={false}
                            showErrorMessage={showErrors && !selectedRequester.contactId}
                        />
                    </div>
                    {items.map(this.renderItem)}
                </div>
            </Popup>
        )
    }
}
