import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import PDFButton from 'components/PDFButton';
import {QuantitySelector, theme, ProductImage} from '@partssourceinc/react-ui-core';
import {getPartCondition, leadTimeDescriptionCartView, leadTimeDescriptionRepairCartView, getPurchaseChoice, getFormularyDisplayType} from 'productUtility';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import {formatMoney} from 'utility';

const InvalidQuantityMessage = styled.p`
    text-align: left;
    margin-left: 20px;
    color: ${theme.dropdownValidationMessageColor};
    font-size: 14px;
    font-weight: 600;

    @media (max-width: ${theme.screenXsMax}) {
        margin-left: 0px;
    }
`;

@connect(
    (state) => ({cart: state.cart, network: state.network, user: state.user, checkout: state.cart.checkout}), 
    _.merge({}, CartStore.actionCreators, UserStore.actionCreators)
)
export default class FacilityGroupCartItem extends React.Component {
    static displayName = 'CartItem';
    static propTypes = {
        allowEdit: PropTypes.bool,
        quoteView: PropTypes.bool,
        cart: CartStore.StateShape,
        network: NetworkStore.StateShape,
        isConfirmation: PropTypes.bool,
        showShippingInfo: PropTypes.bool,
        user: UserStore.StateShape,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            showAssetInformationPopup: false,
            showRequiredInformationEdit: false,
            showOptionalInformationEdit: false,
            updatingQuantity: false,
            showStatCheckBox: false,
            isUrgency: false,
            contractOrderData: null,
        }
    }

    componentWillMount() {
        const {cartItem} = this.props;
        
        axios.get('FormularyService/api/v1/contracts/contractOrder/' + cartItem.lineItemId).then(x => {
            this.setState({contractOrderData: x.data});
        }).catch((e) => {
            console.log(e);
        });
    }

    toggleSaveForLater() {
        const {cartItem, cart, cart: {items, selectedCartItemId}, saveCart, saveItemForLater, selectFacility} = this.props;
        const isSavedForLater = !cartItem.isSavedForLater;

        saveItemForLater(cartItem.cartId, isSavedForLater).then(() => {
            items.filter(x => x.cartId === cartItem.cartId)[0].isSavedForLater = isSavedForLater;
            saveCart(_.merge(cart, {items}));

            // cheat to synchronize the checked flags when applicable
            if (cartItem.cartItemId === selectedCartItemId)
                selectFacility(selectedCartItemId);
        });
    }

    handleQuantityChange(event) {
        const {cartItem, updateQuantity,loadUserCart, saveReduxCartItem} = this.props;

        if (!parseInt(event.target.value)) {
            cartItem.quantity = null;
            saveReduxCartItem(cartItem);
        } else {
            this.setState({updatingQuantity: true});
            updateQuantity(cartItem.lineItemId, parseInt(event.target.value), cartItem.hasBulkPricing).then(() => {
                loadUserCart(); // pricing may have changed
                this.setState({updatingQuantity: false});
                this.qtyInput.focus();
                this.mobileQtyInput.focus();
            });
        }
    }

    removeCartItem() {
        const {removeCartItem, cartItem} = this.props;
        removeCartItem(cartItem.lineItemId);

        this.submitAdobeCartItemRemoved(cartItem);

        dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': [{
                        'name': cartItem.partNumber,
                        'id': cartItem.oemItemNumber,
                        'price': cartItem.price,
                        'brand': cartItem.oemName,
                        'category': '',
                        'variant': `${getPartCondition(cartItem.conditionId)} - ${cartItem.purchaseChoice}`,
                        'quantity': cartItem.quantity,
                    }],
                },

            },
        })
    }

    submitAdobeCartItemRemoved(cartItem) {
        const {cart: {items}} = this.props;
        window.appEventData.push({
            'event': 'Product Removed from Cart',
            'cart': {
                'cartID': Math.min(...items.map(item => item.orderId)).toString() || '0',
            },
            'product': [
                {
                    'price': {
                        'basePrice': cartItem.listPrice ? cartItem.listPrice.toFixed(2).toString() : cartItem.listPrice.toFixed(2).toString(),
                        'priceTier': '',
                        'priceType': cartItem.listPrice ? getPartCondition(cartItem.conditionId) : '',
                        'sellingPrice': cartItem.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'brand': cartItem.oemName,
                        'businessUnit': '',
                        'criticalHardDown': cartItem.urgencyId === 1 ? 'True' : 'False',
                        'facilityID': cartItem.facilityId.toString() || '',
                        'formularyName': cartItem.formularyRuleName || '',
                        'formularyDisplayType': getFormularyDisplayType(cartItem.suppressionBehavior, cartItem.formularyId),
                        'name': cartItem.oemItemNumber,
                        'productCategory': cartItem.category ? cartItem.category : '',
                        'productCondition': getPartCondition(cartItem.conditionId),
                        'productID': cartItem.oemItemNumber,
                        'productLine': '',
                        'productPurchaseType': cartItem.purchaseChoice,
                        'productVariant': Object.keys(cartItem.differentiatingAttributes).length !== 0 ? Object.entries(cartItem.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                        'refId': cartItem.lineItemId.toString(),
                        'sku': cartItem.vString,
                        'vString': cartItem.vString,
                    },
                    'quantity': cartItem.quantity,
                },
            ],
        });
    }

    getFieldValues(values, formType, requester) {
        let {cartItem, loadUserCart} = this.props;
        let {isUrgency} = this.state;
        let fields = [];

        switch (formType) {
            case AssetFormType.Required:
                fields = cartItem.fields.filter(x => !x.isRequired);
                break;
            case AssetFormType.Optional:
                fields = cartItem.fields.filter(x => x.isRequired);
                break;
        }

        const newFields = fields.concat(values.map(v => ({...v, value: (v.value) ? v.value.trim() : v.value})));

        axios.all([this.saveFields(newFields), this.saveRequester(requester), this.saveUrgency(cartItem.lineItemId, cartItem.urgencyId, isUrgency)],).then(() => {
            loadUserCart();

            switch (formType) {
                case AssetFormType.Required:
                    this.setState({showRequiredInformationEdit: false});
                    break;
                case AssetFormType.Optional:
                    this.setState({showOptionalInformationEdit: false});
                    break;
                case AssetFormType.Both:
                    this.setState({showAssetInformationPopup: false});
            }
        });
    }

    saveFields(fields) {
        const {network: {tokenInfo: {userId}}} = this.props;
        return axios.post(`/ShoppingService/api/v1.0/cart/saveFields/${userId}`, fields)
    }

    saveRequester(requester) {
        if (requester && requester.contactId) {
            let {cartItem} = this.props;
            return axios.post('/ShoppingService/api/v1.0/cart/updateRequester', {
                OrderId: cartItem.orderId,
                UserId: requester.contactId,
            });
        } else {
            return new Promise((resolve) => {
                resolve();
            });
        }
    }
    
    saveUrgency(lineItemId, priority, urgency) {
        if ((!urgency && priority === 1) || (urgency && priority === 2))
            return axios.post(`/ShoppingService/api/v1.0/cart/updatePriority/${lineItemId}/${urgency}`);
        return new Promise((resolve) => {
            resolve();
        });
    }

    showShipCutOffData(cartItem) {
        if (cartItem.isContractProOption)
            return '';
        else if (cartItem.orderTypeId === 17)
            return 'n/a';
        else 
            return cartItem.shipCutoffUtcDisplay;
    }

    showESDData(cartItem, isConfirmation) {
        if (cartItem.isContractProOption)
            return '';
        else if (cartItem.suppressLeadTime)
            return 'Pending';
        else if (cartItem.orderTypeId === 17)
            return 'n/a';
        else if (cartItem.orderTypeId !== 4)
            return leadTimeDescriptionCartView(cartItem.estimatedShipDate, cartItem.backOrderShipDate, cartItem.shipCutoffUtc);
        else 
            return leadTimeDescriptionRepairCartView(cartItem.leadTimeDays, isConfirmation)
    }

    render() {
        const {showRequiredInformationEdit, showOptionalInformationEdit, showAssetInformationPopup, updatingQuantity, contractOrderData} = this.state;
        const {cartItem, allowEdit, cartItem: {fields, formularyFields}, user: {settings: {hidePricing, displayCartCutOff}}, quoteView, isConfirmation, forPdf, showShippingInfo, checkout} = this.props;
        const priority = (cartItem.urgencyId === 1);
        const allowQtyEdit = allowEdit && (cartItem.purchaseChoice !== 'Repair'
            || fields.filter(x => x.fieldUid === '44444444-4444-4444-4444-444444444444' && x.isRequired).length === 0) && cartItem.orderType !== 17 && !cartItem.isServiceItem;
        const reqFields = fields.filter(x => x.isRequired);
        const optFields = fields.filter(x => !x.isRequired);
        const allowInfoEdit = cartItem.orderTypeId !== 17;
        const isRepair = cartItem?.orderTypeId === 4;

        let inlineImgStyle = {width: '125px'};
        if (cartItem.orderTypeId === 17) {
            cartItem.imagePath = '/images/icn_repair.png';
            inlineImgStyle = {width: '50px', marginLeft: '20px', maxWidth: '50%'};
        }

        const functionalLocationUid = 'C33AE36B-68F2-47A7-9442-68E92B80594F';
        let fieldFacilityId = reqFields.find(f => f.fieldUid === functionalLocationUid);
        
        let shippingMethod = {};
        let selectedShipOption = {};
        let shippingAttentionDisplay = '';
                
        if (showShippingInfo && checkout.shipments && checkout.shipments.length > 0) {
            const {shippingLocation, shipments, paymentInformation: {poNumber}} = checkout;
            shippingAttentionDisplay = shippingLocation.attentionTo ?
                (shippingLocation.includePo ? 'PO#:' + poNumber + ', ' + shippingLocation.attentionTo : shippingLocation.attentionTo).substring(0, 35) :
                (shippingLocation.includePo ? 'PO#:' + poNumber : '').substring(0, 35);            

            if (!cartItem.isContractProOption) {
                let shipment = checkout.shipments.find(x => x.lineItemIds.includes(cartItem.lineItemId));
                selectedShipOption = shipment.selectedShipOption;
                shippingMethod = shipment.shippingMethod;
            }            
        }

        const minQty = cartItem && cartItem.moq ? cartItem.moq : 1;
        const minQtyMessage = '(Min. Amount Required)';

        return (
            <div className="item-wrapper">
                {cartItem.quantity < minQty &&
                    <div className="note note--warning">
                        <p className="lbl-info">Please select a min. quantity of {minQty}</p>
                    </div>}
                <div className="cart-product-image">
                    {cartItem.imagePath ?
                        <ProductImage  url={cartItem.imagePath} style={inlineImgStyle} /> :
                        <img src="https://assets.partsfinder.com/image/ps-no-image_rectangle?wid=125" style={{width: '125px'}} alt="Not Available" />
                    }
                </div>
                <div className="cart-product-details">
                    {cartItem.isContractProOption && <span className="contract-badge"><span>CONTRACT</span></span>}
                    <div className="cart-product-description">{cartItem.description}</div>
                    <div className="item-attribute">
                        <span>Item #:</span>
                        <span>{cartItem.partNumber}</span>
                    </div>
                    <div className="item-attribute">
                        <span>OEM:</span>
                        <span>{cartItem.oemName}</span>
                    </div>
                    {!cartItem.isServiceItem && <div className="item-attribute">
                        <span>Condition:</span>
                        <span>{getPartCondition(cartItem.conditionId)} {cartItem.purchaseChoice !== 'Repair' && cartItem.purchaseChoice}</span>
                    </div>}
                    {cartItem.paperwork && !quoteView && <div className="rga-info">
                        {cartItem.paperwork.succeeded 
                            ? <PDFButton title="Print Form" buttonType="Link" fileName={`${cartItem.paperwork.rgaNumber}`}
                                pdfUrl={`ShoppingService/api/v1.0/orders/rgapaperwork/${cartItem.lineItemId}/${cartItem.paperwork.rgaNumber}`} />
                            : <div>Forms will be emailed shortly</div>}
                    </div>}
                    <div className={`required-information-desktop`} style={{display: forPdf && 'block'}}>
                        <div className="required-information">
                            Required Information
                            {allowEdit && allowInfoEdit && <span className="edit-asset-info" onClick={() => this.setState({showRequiredInformationEdit: !showRequiredInformationEdit})}>{showRequiredInformationEdit ? 'Cancel' : 'Edit'}</span>}
                        </div>
                        <div className="item-attribute">
                            <span>Facility:</span>
                            <span>{cartItem.facilityName}</span>
                        </div>
                        <div className="item-attribute">
                            <span>Reference #:</span>
                            <span>{cartItem.lineItemId}</span>
                        </div>
                        {(formularyFields || []).map((x, i) => (<div className="item-attribute" key={i}>
                            <span>{x.prompt}:</span>
                            <span>{x.value}</span>
                        </div>))}
                        {showRequiredInformationEdit && <AssetInformationForm
                            fields={reqFields}
                            onSubmit={::this.getFieldValues}
                            formType={AssetFormType.Required}
                            showRequester={true}
                            requesterFacilityId={cartItem.facilityId}
                            requesterId={cartItem.requestorId}
                            isRepairOption={isRepair}
                            onCheckBoxUpdate={(i) => this.setState({isUrgency: i.checked})}
                            priority = {priority}
                        />}
                        {!showRequiredInformationEdit && <div>
                            <div className="item-attribute">
                                <span>Requester:</span>
                                <span>{cartItem.requestorName}</span>
                            </div>
                            {reqFields.filter(x => x.value).map((x, i) => (<div className="item-attribute" key={i}>
                                <span>{x.prompt}:</span>
                                {(x.prompt.length > 30) ? <br /> : null}
                                {(x.value.length > 32) ? <span style={{display: 'block', wordWrap: 'break-word', width: '50%'}}>{x.value}</span> :
                                    <span>{x.value}</span>}
                            </div>))}
                        </div>}
                    </div>
                    {showShippingInfo && 
                            <div className={`shipping-information-desktop`} style={{display: forPdf && 'block'}}>
                                <div className="required-information">
                                    Shipping Information                                    
                                </div>
                                <div className="item-attribute">
                                    <span>Shipping Address:</span>
                                    <span>{checkout.shippingAddress.addressDisplay}</span>
                                </div>
                                <div className="item-attribute">
                                    <span>Shipping ATTN:</span>
                                    <span>{shippingAttentionDisplay}</span>
                                </div>
                                {!cartItem.isContractProOption && !cartItem.isServiceItem && 
                                    <React.Fragment>
                                        <div className="item-attribute">
                                            <span>Carrier:</span>
                                            <span>{shippingMethod.carrierId === 1 ? 'FedEx' : 'UPS'}</span>
                                        </div>
                                        <div className="item-attribute">
                                            <span>Shipping Method:</span>
                                            <span>{selectedShipOption.serviceTypeDescription}</span>
                                        </div>
                                        <div className="item-attribute">
                                            <span>Shipping Insurance:</span>
                                            <span>{shippingMethod.shippingInsurance ? 'Yes' : 'No'}</span>
                                        </div>
                                    </React.Fragment>
                                }                                
                            </div>}
                    {contractOrderData && contractOrderData.assetSiteId ? 
                        <div className="item-attribute">
                            <span>Site Id: </span>
                            <span>{contractOrderData.assetSiteId}</span>
                        </div> : null}
                    {!fieldFacilityId && contractOrderData && contractOrderData.assetFacilityId ? 
                        <div className="item-attribute">
                            <span>Functional Location #: </span>
                            <span>{contractOrderData.assetFacilityId}</span>
                        </div> : null}
                    {optFields.length > 0 && (allowEdit || optFields.filter(x => x.value).length > 0) &&
                        <div className={`required-information-desktop`} style={{display: forPdf && 'block'}}>
                            <div className="required-information">
                                Optional Information
                                {allowEdit && allowInfoEdit && <span className="edit-asset-info" onClick={() => this.setState({showOptionalInformationEdit: !showOptionalInformationEdit})}>{showOptionalInformationEdit ? 'Cancel' : 'Edit'}</span>}
                            </div>
                            {showOptionalInformationEdit ? <AssetInformationForm fields={optFields} onSubmit={:: this.getFieldValues} formType={AssetFormType.Optional} onCheckBoxUpdate={(i) => this.setState({isUrgency: i.checked})} priority = {priority} /> : null}
                            {!showOptionalInformationEdit &&
                            <div>
                                {optFields.filter(x => x.value).map((x, i) => (<div className="item-attribute" key={i}>
                                    <span>{x.prompt}:</span>
                                    {(x.prompt.length > 30) ? <br /> : null}
                                    {(x.value.length > 32) ? <span style={{display: 'block', wordWrap: 'break-word', width: '50%'}}>{x.value}</span> :
                                        <span>{x.value}</span>}
                                </div>))}
                            </div>}
                        </div>
                    }
                    {!forPdf && <div className={`required-information-mobile`} onClick={() => this.setState({showAssetInformationPopup: true})}>
                        Required & Optional Information
                    </div>}
                    <div className="mobile-quantity-price">
                        {allowQtyEdit
                            ? <React.Fragment>
                                <QuantitySelector key={`mobile-cart-qty-${cartItem.lineItemId}`} className="quantity-select"
                                    selectedValue={cartItem.quantity}
                                    ref={(input) => {
                                        this.qtyInput = input;
                                    }}
                                    onChange={::this.handleQuantityChange}
                                    disabled={updatingQuantity ? 'disabled' : undefined}
                                    minQty={minQty} minQtyOptionLabel={minQty > 1 ? minQtyMessage : ''} />
                                {cartItem.quantity < minQty && <InvalidQuantityMessage className="min-order-qty-msg">{`This item has a min. order qty. of ${minQty}`}</InvalidQuantityMessage>}
                            </React.Fragment>
                            : <span className="readonly-price-wrapper">
                                <span>Qty:</span>
                                <span className="qty-value">{cartItem.quantity}</span>
                            </span>
                        }
                        <span className="price">
                            <span className="price-value">{formatMoney(cartItem.price)}</span>
                            <span>/{cartItem.uomCode.toLowerCase()}</span>
                        </span>
                    </div>

                    {allowEdit && <div className="cart-buttons">
                        {!cartItem?.evaluationFee && (<span data-ea-cta-link={getPartCondition(cartItem.conditionId)} onClick={::this.removeCartItem}>REMOVE</span>)}
                        <span className={updatingQuantity || (cartItem.quantity < minQty && cartItem.isSavedForLater) ? 'disable' : ''} onClick={::this.toggleSaveForLater}>{cartItem.isSavedForLater ? 'ADD TO CART' : 'SAVE FOR LATER'}</span>
                    </div>}
                </div>
                { isConfirmation ? 
                    <div className="cart-product-lead">{this.showESDData(cartItem, true)}</div> 
                    : <React.Fragment>
                        <div className="cart-product-cutoff">{this.showShipCutOffData(cartItem)}</div>
                        <div className="cart-product-lead">{this.showESDData(cartItem, false)}</div>
                    </React.Fragment>}
                <div className="cart-product-quantity">
                    {allowQtyEdit ?
                        <React.Fragment>
                            <QuantitySelector key={`desktop-cart-qty-${cartItem.lineItemId}`} selectedValue={cartItem.quantity} onChange={::this.handleQuantityChange} disabled={updatingQuantity ? 'disabled' : undefined} minQty={minQty} minQtyOptionLabel={minQty > 1 ? minQtyMessage : ''} ref={(input) => {
                                this.mobileQtyInput = input;
                            }} />
                            {cartItem.quantity < minQty && <InvalidQuantityMessage className="min-order-qty-msg">{`This item has a min. order qty. of ${minQty}`}</InvalidQuantityMessage>}
                        </React.Fragment>
                        : cartItem.quantity}
                </div>
                <div className="cart-product-price">
                    {!hidePricing && cartItem.originalPromotionPrice > 0 && <div className="original-price">{formatMoney(cartItem.originalPromotionPrice)}</div>}
                    {!hidePricing && formatMoney(cartItem.price)}</div>
                <div className="cart-product-extPrice">
                    {!hidePricing && formatMoney(cartItem.price * cartItem.quantity)}
                </div>
                
                {showAssetInformationPopup && <AssetInformationForm
                    fields={fields}
                    onSubmit={::this.getFieldValues}
                    formType={AssetFormType.Both}
                    readOnly={!allowEdit}
                    onCancel={() => this.setState({showAssetInformationPopup: false})}
                    showRequester={true}
                    requesterFacilityId={cartItem.facilityId}
                    requesterId = {cartItem.requestorId}
                    onCheckBoxUpdate = {(i) => this.setState({isUrgency: i.checked})}
                    priority = {priority}
                />}
            </div>
        );
    }
}
