import moment from 'moment';
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import CartSummary from 'components/CartSummary';
import {Popup} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import * as CartStore from 'stores/Cart';
import {formatMoney, getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import $ from 'jquery';

import 'less/salesQuote.less';
import axios from 'axios';

@withRouter
@connect((state) => ({user: state.user, cart: state.cart}))
export default class SalesQuote extends Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        user: UserStore.StateShape,
        cart: CartStore.StateShape,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            loadingPdf: false,
        }
    }

    printMe() {
        const {location: {pathname}, isConfirmation} = this.props;
        const isReceipt = pathname.indexOf('confirmation') !== -1 || isConfirmation;

        let contents = $('#sales-quote').html();
        let frame1 = $('<iframe />');
        frame1[0].name = 'frame1';
        frame1.css({'position': 'absolute', 'top': '-1000000px'});
        $('body').append(frame1);
        let frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
        frameDoc.document.open();
        // Create a new HTML document.
        frameDoc.document.write(`<html><head><title>Sales ${isReceipt ? 'Receipt' : 'Quote'}</title>`);
        frameDoc.document.write('</head><body>');
        // Append the external CSS file.
        frameDoc.document.write(`<link href="${window.location.origin}/css/salesQuotePDF.css?v=1" rel="stylesheet" type="text/css" />`);
        // Append the DIV contents.
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html>');

        frameDoc.document.close();
        setTimeout(function () {
            window.frames.frame1.focus();
            window.frames.frame1.print();
            frame1.remove();
        }, 500);
    }

    openPdf() {
        const {location: {pathname}, isConfirmation} = this.props;
        const isReceipt = pathname.indexOf('confirmation') !== -1 || isConfirmation;

        this.setState({loadingPdf: true});

        let axiosConfig = {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'},
            responseType: 'arraybuffer',
        };
        const pdfUrl = `/CustomerCommunicationsService/api/v1/report/GeneratePdf`;
        const html = `<link href="${window.location.origin}/css/salesQuotePDF.css?v=1" rel="stylesheet" type="text/css" /> ` + $('#sales-quote').html();
        axios.post(pdfUrl, {html: html}, axiosConfig).then(x => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                this.setState({loadingPdf: false});
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = isReceipt ? 'Receipt.pdf' : 'Quote.pdf';
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);

            this.setState({loadingPdf: false});
        });
    }

    render () {
        const {
            cart: {checkout: {        
                totals: {taxes,creditCardFees, processingFees, minOrderFees, exchangeFees, programFees, oemFees, transitCoverages, shipping},                
                paymentInformation: {paymentMethod},                
                shippingLocation,                
                paymentInformation,                
                billingAddress,                
                facility,
                facilitySettings,
                items,
                shipments,
            }},
            location: {pathname},
            user: {info: {fullName, salesRepFirstName, salesRepLastName}, settings: {dedicatedPhoneNumber, hidePricing}},
            hideTotals, 
            isConfirmation,
            onClose,
            isMultiShipment,
        } = this.props;
        
        const dedicatedPhoneNumberStr = dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(dedicatedPhoneNumber) : defaultPhoneNumber;

        const shippingMethod = shipments && shipments.length > 0 ? shipments[0].shippingMethod : {};
        const selectedShipOption = shipments && shipments.length > 0 ? shipments[0].selectedShipOption : {};

        const hasContractItems = items.some(x => x.isContractProOption);

        const {loadingPdf} = this.state;

        const isCheckout = pathname.indexOf('checkout') !== -1 || isConfirmation;
        const isReceipt = pathname.indexOf('confirmation') !== -1 || isConfirmation;

        const subTot = (items || []).filter(x => x.quantity).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0);

        const subTotal = formatMoney(subTot);
        
        let shippingCostTotal = shipping;
        
        let shippingCostDisplay = formatMoney(shippingCostTotal + transitCoverages + processingFees);

        let total = subTot + shippingCostTotal + minOrderFees + exchangeFees + programFees + oemFees + transitCoverages + processingFees;

        let showCCFees = false;
        if (paymentMethod.value && paymentMethod.value !== 'PO' && creditCardFees) {
            showCCFees = true;
            total += creditCardFees;
        }

        total += taxes;

        total = formatMoney(total);
                
        return (
            <Popup confirmText="Print"
                cancelText="Cancel"
                onConfirm={::this.printMe}
                show={true}
                onCancel={onClose}
                className="sales-quote-dialog"
                hideButtons={false}
                showThirdButton={true}
                onThirdButtonClick={::this.openPdf}
                thirdButtonText="Save PDF"
                thirdButtonLoading={loadingPdf}                
            >
                <div id="sales-quote" style={{width: '100%', marginTop: '30px'}}>

                    <img src={`${window.location.origin}/images/partssourcelogo300.png`} alt="PartsSource logo" />
                    <span className="sales-order-title"><strong>{isReceipt ? 'Sales Order Receipt' : 'Sales Order Quote'}</strong></span>

                    {isReceipt ?
                        <div className="header-table conf">
                            <div className="header-col-1" style={{textAlign: 'left'}}>
                                <div className="header-label">Payment Information</div>
                                <div className="conf">
                                    <span className="address-desc">Billing Address</span>
                                    {isConfirmation ? <span className="address-val">{billingAddress}</span> :
                                        <span className="address-val">
                                            <div>{billingAddress.street1}</div>
                                            <div>{billingAddress.city}, {billingAddress.state} {billingAddress.postalCode}</div>
                                        </span>
                                    }
                                </div>
                                {paymentInformation.poNumber ? <div className="conf">
                                    <span className="">P.O.#:</span>
                                    <span className="val">{paymentInformation.poNumber}</span>
                                </div> : null}
                                <div className="conf">
                                    <span className="">Payment Method:</span>
                                    <span className="val">{paymentInformation.paymentMethod.value === 'PO' ? 'Purchase Order' : 'Credit Card'}</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="header-table">
                            <div className="header-col-1">
                                <div>
                                    <span style={{paddingRight: '5px'}}><strong>Facility:</strong></span> {facility.facilityName}
                                </div>
                                <div><span style={{paddingRight: '5px'}}><strong>Today&apos;s Date:</strong></span>{moment().format('MM/DD/YY')}</div>
                                <div><span style={{paddingRight: '5px'}}><strong>Customer:</strong></span>{fullName}</div>                                
                            </div>
                            <div className="header-col-2">
                                <div className="float-right">
                                    <div className="row">
                                        <span className="desc">Sales Rep:</span>
                                        <span className="val">{salesRepFirstName} {salesRepLastName}</span>
                                    </div>
                                    <div className="row">
                                        <span className="desc">Phone:</span>
                                        <span className="val"><a href={'tel:+1' + dedicatedPhoneNumberStr}>{dedicatedPhoneNumberStr}</a></span>
                                    </div>
                                    <div className="row">
                                        <span className="desc">Fax:</span>
                                        <span className="val">330-562-9901</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <CartSummary allowEdit={false} quoteView={true} checkoutView={true} forPdf={true} showShippingInfo={isReceipt || (isCheckout && !hideTotals)} />
                    {!hidePricing && <React.Fragment>
                        {isCheckout && !hideTotals ? <div className="sales-quote-prices" style={{textAlign: 'right'}}>
                            
                            <div className="sales-quote-price">
                                Subtotal: <span className="price">{subTotal}</span>
                            </div>
                            {programFees > 0 && <div className="sales-quote-price">
                                Program Fee: <span className="price">{formatMoney(programFees)}</span>
                            </div>}
                            {exchangeFees > 0 && <div className="sales-quote-price">
                                Exchange Fee: <span className="price">{formatMoney(exchangeFees)}</span>
                            </div>}
                            {oemFees > 0 && <div className="sales-quote-price">
                                Manufacturer Fee: <span className="price">{formatMoney(oemFees)}</span>
                            </div>}
                            {minOrderFees > 0 && <div className="sales-quote-price">
                                Minimum Order Fee: <span className="price">{formatMoney(minOrderFees)}</span>
                            </div>}

                            <div className="sales-quote-price">
                                Shipping/Processing: <span className="price">{shippingCostDisplay}</span>
                            </div>
                            {showCCFees && <div className="sales-quote-price">
                                    Credit Card Fee: <span className="price">{formatMoney(creditCardFees)}</span>
                            </div>}
                            <div className="sales-quote-price">
                                    Tax: <span className="price">{formatMoney(taxes)}</span>
                            </div>
                            <div className="sales-quote-total">
                                Total: <span className="price">{total}</span>
                            </div>
                            {hasContractItems && <div className="sales-quote-contract-message">
                                <div className="sales-quote-contract-message-inner">
                                    <div className="contract-badge"><span>CONTRACT</span></div>
                                    All on-contract purchases will be billed by the supplier inclusive of Shipping/Processing Fees and Taxes.
                                </div>
                            </div>}
                        </div> : <div className="sales-quote-prices" style={{textAlign: 'right', width: '100%'}}>
                            <div className="sales-quote-price">
                                Subtotal: <span className="price">{subTotal}</span>
                            </div>
                        </div>}
                    </React.Fragment>}
                </div>
            </Popup>
        )
    }
}
