import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';

import FacilityGroupCartItem from 'components/FacilityGroupCartItem';
import * as UserStore from 'stores/User';
import {formatMoney, classNames} from 'utility';
import {RadioButton} from '@partssourceinc/react-ui-core';

@connect((state) => ({user: state.user}))
export default class FacilityGroup extends React.Component {
    static displayName = 'FacilityGroup';
    static propTypes = {
        items: PropTypes.array,
        savedForLater: PropTypes.bool,
        showFacilityHeader: PropTypes.bool,
        isExpanded: PropTypes.bool,
        showRadioButton: PropTypes.bool,
        allowEdit: PropTypes.bool,
        hidePricing: PropTypes.bool,
        quoteView: PropTypes.bool,
        showBulkActions: PropTypes.func,
        user: UserStore.StateShape,
        onSelectFacility: PropTypes.func,
        showShippingInfo: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showSearch: false,
            saveForLaterFilter: '',
        }
    }

    handleFacilityChange(facilityId) {
        const {onSelectFacility} = this.props;
        onSelectFacility(facilityId);
    }

    handleOnSearchBlur = (e) => {
        this.setState({showSearch: e.target.value !== ''});
    }

    handleFilter = (e) => {
        this.setState({saveForLaterFilter: e.target.value ? e.target.value.replace(/\s+/g,'').toLowerCase() : ''});
    }

    render() {
        const {showSearch, saveForLaterFilter} = this.state;
        const {items, isExpanded, showFacilityHeader, showRadioButton, savedForLater, allowEdit, hidePricing, quoteView, showBulkActions, user: {settings: {bulkActions, displayCartCutOff}}, showShippingInfo} = this.props;
        const facilityId = items[0].cartItemId;
        
        const visibleItems = savedForLater && saveForLaterFilter ? 
            items.filter(x => x.searchText.indexOf(saveForLaterFilter) >= 0) : items;

        let CartItems = visibleItems.filter(ci => !ci.isContractProOption).map(ci => <FacilityGroupCartItem forPdf={this.props.forPdf} key={`cart_item_${ci.lineItemId}`} isConfirmation={!showFacilityHeader} cartItem={ci} allowEdit={allowEdit} quoteView={quoteView} showShippingInfo={showShippingInfo} />);
        let ContractItems = visibleItems.filter(ci => ci.isContractProOption).map(ci => <FacilityGroupCartItem key={`cart_item_${ci.lineItemId}`} isConfirmation={!showFacilityHeader} cartItem={ci} allowEdit={allowEdit} quoteView={quoteView} showShippingInfo={showShippingInfo} />);
            
        const facilitySubTotal = formatMoney(items.filter(x => x.quantity).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0));
        let wrapperClass = isExpanded ? 'facility-group-wrapper' : 'facility-group-wrapper collapsed';
        const headerDetailClass = savedForLater ? 'cart-detail-header saveForLater' : 'cart-detail-header';

        if (hidePricing) wrapperClass += ' hide-pricing-wrapper';

        if (showFacilityHeader)
            wrapperClass += ' show-lead-time';
        else
            wrapperClass += ' show-lead-time-confirmation';

        const isPastCutoff = allowEdit && displayCartCutOff && items.some(x => x.isPastCutOff);
        const showContractHeader = CartItems.length > 0 && ContractItems.length > 0;

        const searchBoxClasses = {
            'form-control': true,
            'form-control hidden-search-box': true,
            'is-visible': showSearch,
        };

        return (
            <div className={wrapperClass}>
                {isExpanded ?
                    <div>
                        <div className={headerDetailClass}>
                            {showFacilityHeader ?
                                <span className="cart-facility-header">
                                    {showRadioButton ?
                                        <RadioButton  
                                            id={`facility_rad_${facilityId}`} 
                                            checked={isExpanded} 
                                            value={facilityId} 
                                            onChange={::this.handleFacilityChange} 
                                            name={'facility-selection'} >
                                            <span className="facility-name">{items[0].facilityName}</span>
                                            {!hidePricing && <span className="facility-subtotal">({facilitySubTotal})</span>}
                                            {bulkActions && <span className="bulk-actions" onClick={() => showBulkActions(items)}>Bulk Actions</span>}
                                        </RadioButton> :
                                        <div>
                                            <span className="facility-name">{items[0].facilityName}</span>
                                            {!hidePricing && <span className="facility-subtotal">({facilitySubTotal})</span>}
                                            {bulkActions && <span className="bulk-actions" onClick={() => showBulkActions(items)}>Bulk Actions</span>}
                                        </div>
                                    }
                                </span> 
                                : <span>
                                    <span className="cart-product-image" />
                                    <span className="cart-product-details">{quoteView ? 'Item Details' : ''}</span>
                                </span>
                            }
                            {savedForLater && <div className={`hidden-search-wrapper ${!showSearch ? 'hidden-search-wrapper--no-margin' : 'hidden-search-wrapper--open'}`}>
                                <input
                                    type="search"
                                    className={classNames(searchBoxClasses)}
                                    onFocus={() => this.setState({showSearch: true})}
                                    onBlur={this.handleOnSearchBlur}
                                    onChange={this.handleFilter}
                                    placeholder={showSearch && 'Search'}
                                    autoComplete="grid-search"
                                />
                                <i className="glyphicon glyphicon-search" />
                            </div>}
                            {savedForLater && <span className="cart-facility-header">Saved For Later</span>}
                            {showFacilityHeader ? <React.Fragment>
                                <div className="cart-product-cutoff">Cut-off Time</div>
                                <div className="cart-product-lead"> Est. Shipping Date</div>
                            </React.Fragment>
                                : <div className="cart-product-lead"> Est. Shipping Date</div>
                            }
                            <div className="cart-product-quantity">Quantity</div>
                            <div className="cart-product-price">{!hidePricing ? 'Unit Price' : ''}</div>
                            <div className="cart-product-extPrice">{!hidePricing ? 'Ext. Price' : ''}</div>
                        </div>
                        {isPastCutoff && !savedForLater && <div className="cart-cutoff">
                            <i className="fa fa-exclamation" />
                            One or more items in your cart are past cut off and may not ship today.
                        </div>}
                        
                        {showContractHeader && <div className="contract-header">Contract Items</div>}
                        {ContractItems}
                        {showContractHeader && <div className="contract-header">Non-Contract Items</div>}
                        {CartItems}
                    </div> :
                    <div>
                        <div className="cart-detail-header summary">
                            {showFacilityHeader ?
                                <span className="cart-facility-header">
                                    {showRadioButton ?
                                        <RadioButton  
                                            id={`facility_rad_${facilityId}`} 
                                            checked={isExpanded} 
                                            value={facilityId} 
                                            onChange={() => this.handleFacilityChange(facilityId)} 
                                            name={'facility-selection'} >

                                            <span className="facility-name">{items[0].facilityName}</span>
                                            {!hidePricing && <span className="facility-subtotal">({facilitySubTotal})</span>}

                                        </RadioButton> :
                                        <div>
                                            <span className="facility-name">{items[0].facilityName}</span>
                                            {!hidePricing && <span className="facility-subtotal">({facilitySubTotal})</span>}
                                        </div>}
                                </span> : <span />
                            }
                        </div>
                        {items.map(ci => (<div key={`cart_item_summary_${ci.lineItemId}`} className="item-summary">
                            ({ci.quantity}) {ci.partNumber} - {ci.description}
                        </div>))}
                    </div>
                }
            </div>
        );
    }
}
