import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types'
import * as React from 'react';
import {connect} from 'react-redux';

import BulkActionsForm from 'components/BulkActionsForm';
import FacilityGroup from 'components/FacilityGroup';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';

import 'less/cartsummary.less';

@connect((state) => ({cart: state.cart, user: state.user}), CartStore.actionCreators)
export default class CartSummary extends React.Component {
    static displayName = 'CartSummary';

    static propTypes = {
        allowEdit: PropTypes.bool,
        showSavedForLater: PropTypes.bool,
        checkoutView: PropTypes.bool,
        checkoutSummaryView: PropTypes.bool,
        quoteView: PropTypes.bool,
        cart: CartStore.StateShape,
        user: UserStore.StateShape,
        showShippingInfo: PropTypes.bool,
        ...CartStore.ActionShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            ShowCartDetails: true,
            showBulkActions: false,
            bulkItems: [],
            requesterList: [],
            loadingContacts: false,
        };
    }

    selectFacility(cartItemId) {
        const {saveCart, selectFacility, cart} = this.props;

        let items = cart.items;
        items.forEach(x => x.isChecked = false);
        items.filter(x => x.cartItemId === cartItemId).forEach(x => x.isChecked = true);

        selectFacility(cartItemId);
        saveCart(_.merge(cart, {items: items, selectedCartItemId: cartItemId}));
    }

    renderCheckoutSummary() {
        const {cart: {checkout: {items}}} = this.props;

        const contractItems = items.filter(x => x.isContractProOption);
        const nonContractItems = items.filter(x => !x.isContractProOption);

        const showContractHeader = contractItems.length > 0 && nonContractItems.length > 0;

        return (<div>
            {showContractHeader && <div className="contract-header">Contract Items</div>}
            {contractItems.map(x => (<div key={`cart_checkout_item_${x.lineItemId}`} className="item-summary">
                ({x.quantity}) {x.partNumber} - {x.description} {x.isContractProOption && <span className="contract-badge"><span>CONTRACT</span></span>}
            </div>))}
            {showContractHeader && <div className="contract-header" style={{marginTop: '10px'}}>Non-Contract Items</div>}
            {nonContractItems.map(x => (<div key={`cart_checkout_item_${x.lineItemId}`} className="item-summary">
                ({x.quantity}) {x.partNumber} - {x.description} {x.isContractProOption && <span className="contract-badge"><span>CONTRACT</span></span>}
            </div>))}
        </div>)
    }

    showBulkActionsDialog(bulkItems) {
        const facilityId = bulkItems[0].facilityId;
        this.setState({loadingContacts: true});
        axios.get(`/ShoppingService/api/v1/company/contacts/${facilityId}`).then(x => {
            this.setState({requesterList: x.data, showBulkActions: true, bulkItems: bulkItems, loadingContacts: false});
        });
    }

    onBulkActionsSave() {
        const {loadUserCart} = this.props;
        loadUserCart().then(() => {
            this.setState({showBulkActions: false});
        });
    }

    render() {
        const {showBulkActions, requesterList, bulkItems} = this.state;

        const {
            allowEdit,
            checkoutView,
            checkoutSummaryView,
            cart: {cartItems, savedItems, selectedCartItemId, uniqueFacilities, checkout},
            showSavedForLater,
            user: {settings: {hidePricing}},
            quoteView,
            showShippingInfo,
        } = this.props;

        let facilityItems = [];
        let facilityCartItems = [];

        if (showSavedForLater) {
            facilityItems.push(savedItems);

            facilityCartItems = facilityItems.map(items =>
                (<FacilityGroup items={items} key={`facility_group_${items[0].cartItemId}`} isExpanded={true}
                    showFacilityHeader={false} hidePricing={hidePricing}
                    savedForLater={true} allowEdit={allowEdit} />));
        } else {
            let autoExpand = uniqueFacilities.length === 1;

            if (checkoutView) {
                if (checkout.items.length) {
                    if (checkout.paperwork && checkout.paperwork.length > 0) {
                        checkout.paperwork.forEach(x => {
                            let item = checkout.items.filter(c => c.lineItemId === x.lineItemId)[0];
                            item.paperwork = x;
                        });
                    }
                    facilityItems.push(checkout.items);
                }
            } else {
                for (let i = 0; i < uniqueFacilities.length; i++)
                    facilityItems.push(cartItems.filter(x => x.cartItemId === uniqueFacilities[i].cartItemId));
            }

            facilityCartItems = facilityItems.map(items =>
                (<FacilityGroup items={items} key={`facility_group_2_${items[0].cartItemId}`} forPdf={this.props.forPdf}
                    isExpanded={items[0].cartItemId === selectedCartItemId || checkoutView} showFacilityHeader={!checkoutView}
                    showRadioButton={!autoExpand && !checkoutView} onSelectFacility={::this.selectFacility} showBulkActions={::this.showBulkActionsDialog}
                    savedForLater={false} allowEdit={allowEdit} hidePricing={hidePricing} quoteView={quoteView} showShippingInfo={showShippingInfo} />));
        }

        return (
            <div className="cart-summary-wrapper">
                {checkoutSummaryView ?
                    <div className="cart-summary">
                        <div><i className="fa fa-shopping-cart" aria-hidden="true" /> <h3>Cart Summary</h3></div>
                        {this.renderCheckoutSummary()}
                    </div> :
                    <div className="cart-summary">
                        {!allowEdit && <div className="summary-title">
                            <i className="fa fa-shopping-cart" aria-hidden="true" /> <h3>Cart Summary</h3>
                        </div>}
                        <div className="cart-details">
                            {facilityCartItems}
                        </div>
                    </div>}
                {showBulkActions && <BulkActionsForm requesterList={requesterList} items={bulkItems} onClose={() => this.setState({showBulkActions: false})} onSave={::this.onBulkActionsSave} />}
            </div>
        );
    }
}
